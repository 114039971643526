import * as Axios from './AxiosUtil';

const LoginService = {
    login: function (emailAddress, password) {
        return Axios.post('/api/v1/auth', { loginId: emailAddress, password})
        .then(response => {
            return { successful: true,  tokenType: response.type, token: response.token }
        })
        .catch(err => {
            if (err.status === 401) {
                return err.response.data;
            } else {
                return { successful: false, message: "Something went wrong!" }
            }
        });
    }
}

export default LoginService;
