import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log('REACT_APP_API_BASE_URL: ', BASE_URL);
const defaultConfig = {
  headers: {
    'Authorization': null
  }
};

export function setToken(tokenType, token) {
  defaultConfig.headers.Authorization = tokenType + ' ' + token;
}

export function get(path, config=defaultConfig) {
  return axios
  .get(BASE_URL + path, config)
  .then(res => {
    console.log(res);
    return res.data;
  });

}

export function post(path, data={}, config=defaultConfig) {
  return axios
  .post(BASE_URL + path , data, config=defaultConfig)
  .then(res => {
    console.log(res);
    return res.data;
  });
}

export function put(path, data={}, config=defaultConfig) {
  return axios
  .put(BASE_URL + path , data, config)
  .then(res => {
    console.log(res);
    return res.data;
  });
}

export function patch(path, data={}, config=defaultConfig) {
  return axios
  .patch(BASE_URL + path , data, config)
  .then(res => {
    console.log(res);
    return res.data;
  });
}

export function del(path, id, config=defaultConfig) {
  return axios
  .patch(BASE_URL + path + id, null, config)
  .then(res => {
    console.log(res);
    return res.data;
  });
}
