import { React, useState } from 'react';
import Card from 'react-bootstrap/Card';

function ClientsPage(props) {

    return (
        <Card>
            <Card.Body>
                <Card.Title className="CardTitle"><span>{' '}</span>Clients</Card.Title>
            </Card.Body>
        </Card>
    );
}

export default ClientsPage;
  