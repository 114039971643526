import { React } from 'react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import QuotedText from './QuotedText';

import './ItemModal.css';

function ItemModel(props) {

  const getStyles = (styles) => {
    return {
      position: 'absolute',
      top: '45%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: styles.width || '50%',
      height: styles.height || "50%",
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4    
    }
  }
  return (
    <Modal
      open={props.show}
      onClose={props.onClose}>
    <Box sx={getStyles(props.styles)}>
      <Typography variant="h5" gutterBottom>
        <QuotedText>{props.title}</QuotedText>
      </Typography>
      {props.content}
    </Box>
  </Modal>
  );
}

export default ItemModel;