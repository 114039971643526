import React from 'react';
import './App.css';

import Login from './pages/Login';
import PageLayout from './pages/PageLayout'

import { useSelector } from 'react-redux'

function App() {
  const authentication = useSelector((state) => state.authentication)

  return (
    <>
      {
        authentication.authenticated
          ? <PageLayout />
          : <Login />
      }  
    </>
  );
}

export default App;