import { React } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid2';

import Menu from '../components/Menu';
import DashboardPage from './DashboardPage';
import EventsPage from './EventsPage';
import InventoryPage from './InventoryPage';
import ResourcesPage from './resources/ResourcesPage';
import ClientsPage from './ClientsPage';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));


function PageLayout(props) {

    return (
      <Box sx={{ flexGrow: 1 }} style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20 }}>
        <Router>
          <Grid container spacing={2}>
            <Grid size={2}>
              <Item>
                <img src="./assets/images/logo2.png" alt="logo" width="100%"/>
                <Menu />
              </Item>
            </Grid>
            <Grid size={10}>
              <Item sx={{ bgcolor: 'background.paper', minHeight: "92vh" }}>
                  <Routes>
                    <Route path="/dashboard" element={<DashboardPage />} />
                    <Route path="/events" element={<EventsPage />} />
                    <Route path="/inventory" element={<InventoryPage />} />
                    <Route path="/resources" element={<ResourcesPage />} />
                    <Route path="/clients" element={<ClientsPage />} />
                  </Routes>
              </Item>
            </Grid>
          </Grid>
        </Router>
    </Box>
    );
  }
  
  export default PageLayout;
  