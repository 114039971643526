import * as Axios from './AxiosUtil';

const ENDPOINT_URL = '/api/v1/resource';

const ResourceService = {
    getAll: function () {
        return Axios.get(ENDPOINT_URL);
    },
    addResource: function (resource) {
        return Axios.post(ENDPOINT_URL, resource)
        .then(response => {
            const data = response.data;
            console.log(data)
            return { successful: true,  ...data }
        })
        .catch(err => {
            console.log(err)
            if (err.status === 401) {
                return err.response.data;
            } else {
                return { successful: false, ...err.response.data }
            }
        });
    },
    deleteResource: function (id) {
        return Axios.del(ENDPOINT_URL, id)
        .then(response => {
            return { successful: true }
        })
        .catch(err => {
            console.log(err)
            if (err.status === 401) {
                return err.response.data;
            } else {
                return { successful: false, message: "Something went wrong!" }
            }
        });
    }
}

export default ResourceService;
