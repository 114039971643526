import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import FormButton from  '../components/FormButton';

import QuotedText from '../components/QuotedText';
import { loginSuccess, loginFail } from '../app/slices/authenticationSlice';
import LoginService from '../service/LoginService';
  
function Login(props) {

    const { errorMessage, successMessage } = useSelector((state) => state.authentication)
    const dispatch = useDispatch()

    const [ data, setData ] = useState({emailAddress: "", password: "" });
    const handleChange = (field, value) => setData({...data, [field]: value})

    const handleLogin = () => {
        LoginService.login(data.emailAddress, data.password)
        .then(response => {
            if (response.successful) {
                dispatch(loginSuccess({ tokenType: response.tokenType, token: response.token}))
            } else {
                dispatch(loginFail(response.message))
            }
        });
    }

    return (
        <Box sx={{ flexGrow: 1 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            marginLeft="20px"
            marginRight="20px">
            <Grid container spacing={2}>
                <Grid size={6}>
                    <img src="./assets/images/event1.jpg" alt="Event"
                        style={{ width: '100%', height: 'auto', objectFit: 'cover' }}>
                    </img>
                </Grid>
                <Grid size={6}>
                    <Stack spacing={6} style={{ marginLeft: "10%", marginRight: "10%" }}>
                        <Typography variant="h5" gutterBottom><QuotedText>Login</QuotedText></Typography>
                        { errorMessage && <Alert variant="outlined" severity="error">{errorMessage}</Alert>}
                        { successMessage && <Alert variant="outlined" severity="success">{successMessage}</Alert>}
                        <TextField required label="Email Address" value={data.emailAddress}
                            onChange={(e) => handleChange('emailAddress', e.target.value)} />
                        <TextField required label="Password" type="password" value={data.password}
                            onChange={(e) => handleChange('password', e.target.value)} />
                        <FormButton size='large' label="Login to account" onClick={() => handleLogin()}>
                        </FormButton>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
  }
  
  export default Login;
  