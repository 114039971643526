import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import DashboardSharpIcon from '@mui/icons-material/DashboardSharp';
import StadiumSharpIcon from '@mui/icons-material/StadiumSharp';
import Inventory2SharpIcon from '@mui/icons-material/Inventory2Sharp';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import BusinessCenterSharpIcon from '@mui/icons-material/BusinessCenterSharp';
import AssessmentSharpIcon from '@mui/icons-material/AssessmentSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';


import { logout } from '../app/slices/authenticationSlice';

import './Menu.css';

function Menu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', minHeight: "75vh" }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem className='MenuItem' onClick={() => navigate('/dashboard')}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <DashboardSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem className='MenuItem' onClick={() => navigate('/events')}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <StadiumSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Events" />
          </ListItem>
          <ListItem className='MenuItem' onClick={() => navigate('/inventory')}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <Inventory2SharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Inventory" />
          </ListItem>
          <ListItem className='MenuItem' onClick={() => navigate('/resources')}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <PeopleSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Resources" />
          </ListItem>
          <ListItem className='MenuItem' onClick={() => navigate('/clients')}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <BusinessCenterSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Clients" />
          </ListItem>
          <ListItem className='MenuItem' onClick={() => navigate('/reports')}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <AssessmentSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Reports" />
          </ListItem>
          <Divider />
          <ListItem className='MenuItem' onClick={() => dispatch(logout())}>
            <ListItemAvatar>
              <Avatar className="Icon">
                <LogoutSharpIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </nav>
    </Box>
    );
  }
  
  export default Menu;