import { configureStore } from '@reduxjs/toolkit'
import authenticationSlice from '../slices/authenticationSlice'
//import * as Axios from '../../AxiosUtil';

const reHydrateStore = () => {
  if (localStorage.getItem('appState') !== null) {
    let appState = JSON.parse(localStorage.getItem('appState')); // re-hydrate the store
    if (appState.authentication != null) {
      //AxiosUtil.setToken(appState.authentication.tokenType, appState.authentication.token);
    }
    return appState;
  }
};

export const store = configureStore({
  reducer: {
    authentication: authenticationSlice
  },
  preloadedState: reHydrateStore()
})

store.subscribe(() => {
  // persist your state
  localStorage.setItem('appState', JSON.stringify(store.getState()))
})