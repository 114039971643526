import { useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import BadgeIcon from '@mui/icons-material/Badge';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import WorkIcon from '@mui/icons-material/Work';

import FormButton from  '../../components/FormButton';
import FormDateInput from '../../components/FormDateInput';
import FormRadioInput from '../../components/FormRadioInput';
import FormTextInput from '../../components/FormTextInput';

import ResourceService from '../../service/ResourceService';

function AddResource(props) {
  const [ data, setData ] = useState({
    employeeId: "",
    sourceType: null,
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    primarySpecialization: "",
    secondarySpecialization: "",
    chargeableAmount: 0
  });
  // const [ data, setData ] = useState({
  //   employeeId: "EMP001",
  //   sourceType: "External",
  //   firstName: "S",
  //   lastName: "R",
  //   dateOfBirth: "2024-09-20T18:30:00.000Z",
  //   phoneNumber: "9876543210",
  //   alternatePhoneNumber: "9876543211",
  //   primarySpecialization: "Camera operator",
  //   secondarySpecialization: "Drone Operator",
  //   chargeableAmount: 3000
  // });
  const [ error, setError ] = useState(null);

  const handleFormChange = (field, e) => setData({...data, [field]: e.target.value})
  const handleDateChange = (field, newValue) => setData({...data, [field]: newValue})

  const handleSubmit = () => {
    ResourceService.addResource(data)
      .then(data => {
        if (data.successful) {
          props.onSuccess();
        } else {
          setError(data.message);
        }
      });
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      { error && <Alert variant="outlined" severity="error">{error}</Alert>}
      <Grid container spacing={2}>
        <Grid size={6}>
          <Typography variant="h6" gutterBottom>Personal Info</Typography>
        </Grid>
        <Grid size={6}>
          <Typography variant="h6" gutterBottom>Employee Info</Typography>
        </Grid>
        <Grid size={6}>
          <FormRadioInput label="Source Type" name="sourceType" size="small" value={data.sourceType} values={["New", "External"]}
            onChange={(e) => handleFormChange('sourceType', e)} />
        </Grid>
        <Grid size={6}>
          <FormTextInput name="employeeId" label="Employee ID" size="small" value={data.employeeId} icon={<BadgeIcon />}
            onChange={(e) => handleFormChange('employeeId', e)} />
        </Grid>
        <Grid container size={6}>
          <Grid size={5}>
            <FormTextInput name="firstName" label="First Name" size="small" value={data.firstName} icon={<PersonIcon />}
              onChange={(e) => handleFormChange('firstName', e)} />
          </Grid>
          <Grid size={5}>
            <FormTextInput name="lastName" label="Last Name" size="small" value={data.lastName} icon={<PersonIcon />}
              onChange={(e) => handleFormChange('lastName', e)} />        
          </Grid>
        </Grid>
        <Grid size={6}>
          <FormTextInput name="primarySpecialization" label="Primary Specialization" size="small" value={data.primarySpecialization} icon={<WorkIcon />}
            onChange={(e) => handleFormChange('primarySpecialization', e)} />
        </Grid>
        <Grid size={6}>
          <FormDateInput name="dateOfBirth" label="Date of Birth" size="small" value={data.dateOfBirth}
            onChange={(e) => handleDateChange('dateOfBirth', e)} />
        </Grid>
        <Grid size={6}>
          <FormTextInput name="secondarySpecialization" label="Secondary Specialization" size="small" value={data.secondarySpecialization} icon={<WorkIcon />}
            onChange={(e) => handleFormChange('secondarySpecialization', e)} />
        </Grid>
        <Grid size={6}>
          <FormTextInput name="phoneNumber" label="Phone Number" size="small" value={data.phoneNumber} icon={<PhoneIphoneIcon />}
            onChange={(e) => handleFormChange('phoneNumber', e)} />
        </Grid>
        <Grid size={6}>
          <FormTextInput name="chargeableAmount" label="Chargeable Amount" type="number" size="small" value={data.chargeableAmount} icon={<CurrencyRupeeIcon />}
            onChange={(e) => handleFormChange('chargeableAmount', e)} />
        </Grid>
        <Grid size={6}>
          <FormTextInput name="alternatePhoneNumber" label="Alternate Phone Number" size="small" value={data.alternatePhoneNumber} icon={<PhoneIphoneIcon />}
            onChange={(e) => handleFormChange('alternatePhoneNumber', e)} />
        </Grid>
        <Grid size={6}>{' '}
        </Grid>
        <Grid size={10}>
          <FormButton style={{float: "right"}} label="Submit" onClick={() => handleSubmit()}></FormButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddResource;