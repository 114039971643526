import { React } from 'react';

import './QuotedText.css';

function QuotedText(props) {

  return (
      <>
        <span className='Quote'></span>
        {props.children}
      </>
  );
}

export default QuotedText;