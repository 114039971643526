import { React, useState, useEffect } from 'react';

import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import AddIcon from '@mui/icons-material/Add';
import PersonIcon from '@mui/icons-material/Person';
//import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import QuotedText from '../../components/QuotedText';

import AddResource from './AddResource';
import ItemModal from '../../components/ItemModal';
import ResourceService from '../../service/ResourceService';

const TABS = [
    { key: "All", name: "All Resources" },
    { key: "Available", name: "Available" },
    { key: "Booked", name: "Booked" },
    { key: "In-Event", name: "In-Event" }
];

const TABLE_COLUMNS = [
  "Resource Details", "Mobile Number", "Status", "Specialization", "Event", "Location", "Branch", "Payable", "Expiry Date", "Action"
];
function ResourcesPage(props) {

  const [ localProps, setLocalProps ] = useState({
    activeTab: 0,
    showModal: false,
    listRetrieved: false,
    resourceUpdated: false,
    message: "Resource updated successfully!"
  });
  const [resourcesData, setResourcesData] = useState([]);

  const { activeTab, showModal, listRetrieved, resourceUpdated, message } = localProps;
  
  useEffect(() => {
    if (!listRetrieved) {
      ResourceService.getAll()
        .then(data => {
          setResourcesData(data);
          setLocalProps({...localProps, listRetrieved: true });
      });
    }
  });

  const handleTabChange = (event, newValue) => setLocalProps({...localProps, activeTab: newValue, resourceUpdated: false });
  const handleOnSuccess = () => {
    setLocalProps({ ...localProps, showModal: false, resourceUpdated: true, listRetrieved: false, message: "Resource updated successfully!" });
  }

  const handleClose = () => setLocalProps({ ...localProps, showModal: false });
  const handleShow = () => setLocalProps({ ...localProps, showModal: true });
  // const handleDelete = () => {
  //   ResourceService.getAll()
  //       .then(data => {
  //         setLocalProps({...localProps, resourceUpdated: true, message: "Resource deleted successfully!" });
  //     });
  // }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        <QuotedText>Resources</QuotedText>
        <Button variant="contained" color="warning" style={{float: "right"}} startIcon={<AddIcon />} onClick={handleShow}>Add Reseource</Button>
      </Typography>
      <ItemModal show={showModal} title="Add Resource" content={<AddResource onSuccess={handleOnSuccess} />}
        styles={{width: "50%", height: "75%" }} onClose={handleClose} />
      <Tabs value={activeTab} TabIndicatorProps={{style: {background:'#e65100'}}} onChange={handleTabChange}>
        { TABS.map(tab => <Tab id={'tab-' + tab.key} key={tab.key} label={tab.name} /> )}
      </Tabs>
      { resourceUpdated && <Alert variant="filled" severity="success" style={{ marginTop: "10px", marginBottom: "10px"}}>{message}</Alert>}
      { TABS.map((tab, index) => 
        <>
        <div role="tabpanel" hidden={activeTab !== index} aria-labelledby={'tab-' + tab.key}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {TABLE_COLUMNS.map(col => <TableCell key={'TableHeader' + col}>{col}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                { resourcesData.length === 0 && 
                  <TableRow key="NoDataFound" sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell colSpan={TABLE_COLUMNS.length} style={{textAlign: "center"}}>No Data Found</TableCell>
                  </TableRow>
                }
                {resourcesData.map(resource => 
                  <TableRow key={resource.employeeId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell key="Cell-EmployeeId">
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item size={4}>
                        <Grid item size={6} sx={{justifyContent: "middle"}}>
                          {resource.sourceType === 'EXTERNAL' ? resource.sourceType : ""}
                        </Grid>
                        <Grid item size={6}>
                          <Avatar>
                            <PersonIcon />
                          </Avatar>
                        </Grid>
                      </Grid>
                      <Grid item size={6}>
                        <Grid item size={6} sx={{justifyContent: "middle"}}>
                          {' '}
                        </Grid>
                        <Grid>
                          {resource.employeeId}
                        </Grid>
                        <Grid>
                          {resource.lastName + '. ' + resource.firstName}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* {resource.employeeId}
                    {resource.firstName}
                    {resource.lastName} */}
                    </TableCell>
                    <TableCell key="Cell-PhoneNumber">{resource.phoneNumber}</TableCell>
                    <TableCell key="Cell-Status">{resource.status}</TableCell>
                    <TableCell key="Cell-Specialization">{resource.primarySpecialization}</TableCell>
                    <TableCell key="Cell-Event">{resource.event}</TableCell>
                    <TableCell key="Cell-Location">{resource.location}</TableCell>
                    <TableCell key="Cell-Branch">{resource.branch}</TableCell>
                    <TableCell key="Cell-ChargeableAmount">{resource.chargeableAmount}</TableCell>
                    <TableCell key="Cell-ExpiryDate">{resource.expiryDate}</TableCell>
                    <TableCell key="Cell-Actions">{' '}
                      {/* <Button variant="outline" color="warning" startIcon={<DeleteTwoToneIcon />} style={{color: "orange"}}
                        onClick={() => handleDelete(resource.employeeId)} /> */}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </>
      )}
    </>
    );
  }
  
  export default ResourcesPage;
  