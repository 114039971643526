import { createSlice } from '@reduxjs/toolkit'
import * as Axios from '../../service/AxiosUtil';

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: {
    authenticated: false,
    token: null,
    errorMessage: null,
    successMessage: null
  },
  reducers: {
    loginSuccess(state, action) {
      Axios.setToken(action.payload.tokenType, action.payload.token);
      state.authenticated = true
      state.tokenType=action.payload.tokenType;
      state.token=action.payload.token;
      state.errorMessage = null;
      state.successMessage = null;
    },
    loginFail(state, action) {
      state.authenticated = false;
      state.errorMessage = action.payload;
      state.successMessage = null;
    },
    logout(state, action) {
      state.authenticated = false;
      state.errorMessage = null;
      state.successMessage = "Logged out successfuly!";
    }
  },
})

export const { loginSuccess, loginFail,logout } = authenticationSlice.actions
export default authenticationSlice.reducer