import Button from '@mui/material/Button';

function FormButton(props) {

  return (
    <Button
      size={props.size || 'medium'}
      variant={props.variant || 'contained'}
      color='warning'
      onClick={props.onClick}
      style={props.style}
    >
      {props.label}
    </Button>
  );
}

export default FormButton;