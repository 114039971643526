import { React } from 'react';

import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';

function FormTextInput(props) {

  return (
    <>
      <InputLabel htmlFor={props.name}>{props.label}</InputLabel>
      <TextField
          id={props.name}
          type={props.type || 'text'}
          value={props.value}
          size={props.size || 'normal'}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position={props.iconPosition || "start"}>{props.icon}</InputAdornment>,
            },
          }}
          onChange={props.onChange}
      />
    </>
  );
}

export default FormTextInput;