
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

function FormDateInput(props) {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker label={props.label} value={dayjs(props.value)} size={props.size || 'Normal'}
        onChange={(newValue) => props.onChange(newValue)} />
    </LocalizationProvider>
  );
}

export default FormDateInput;