import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

function FormRadioInput(props) {
  return (
    <>
      <FormLabel>{props.label}</FormLabel>
      <RadioGroup row name={props.name} defaultValue={props.value}
          onChange={props.onChange}>
            {props.values.map((value, index) => <FormControlLabel key={'Radio'+index} value={value} control={<Radio size={props.size || 'Normal'}/>} label={value} /> )}
      </RadioGroup>
    </>
  );
}

export default FormRadioInput;