import { React, useState } from 'react';
import Card from 'react-bootstrap/Card';

function EventsPage(props) {
    return (
        <Card>
            <Card.Body>
                <Card.Title className="CardTitle"><span>{' '}</span>Events</Card.Title>
            </Card.Body>
        </Card>
    );
}

export default EventsPage;
  