import { React, useState } from 'react';
import Card from 'react-bootstrap/Card';

function InventoryPage(props) {
    return (
        <Card>
            <Card.Body>
                <Card.Title className="CardTitle"><span>{' '}</span>Inventory</Card.Title>
            </Card.Body>
        </Card>
    );
}

export default InventoryPage;
  